import * as Sentry from '@sentry/browser';
import {
  IEditorReadyPayload,
  IInstanceData,
} from '@wix/platform-editor-helpers';

export const SENTRY_DSN =
  'https://93242644552a44049bb4944c2cf96a36@sentry.io/1529614';

export function withErrorReporting(fn) {
  return async (...args) => {
    try {
      await fn(...args);
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setExtra('arguments', args);
        Sentry.captureException(error);
      });

      throw error;
    }
  };
}

function parseJWT(jwt: string): any {
  return JSON.parse(atob(jwt.split('.')[1]));
}

export function setSentryContext({
  firstInstall,
  initialAppData,
}: IEditorReadyPayload) {
  const instanceData: IInstanceData = parseJWT(initialAppData.instance);

  Sentry.setUser({
    id: instanceData.uid,
  });

  Sentry.setTags({
    firstInstall: String(firstInstall),
    metaSiteId: instanceData.metaSiteId,
  });

  Sentry.setExtras({
    ...initialAppData,
    instanceData,
  });
}
